import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import marker from '../../img/red-pin.svg';

const ListingMap = ({ title, lat, lng }) => {
	const latLng = { lat: parseFloat(lat), lng: parseFloat(lng) };
	return (
		<div style={{ height: '100vh', width: '100%' }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: 'AIzaSyDbA3ifgEFvRv_TdcbtxBJ7-IlyaFwmr9Q' }}
				defaultCenter={latLng}
				defaultZoom={15}
			>
				<Marker lat={lat} lng={lng} text="title"></Marker>
			</GoogleMapReact>
		</div>
	);
};

const Marker = ({ lat, lng, text }) => {
	const StyledMarker = styled.img`
		width: 32px;
		height: 32px;
		position: relative;
		top: -16px;
		left: -16px;
	`;
	return <StyledMarker src={marker} />;
};

export default ListingMap;
