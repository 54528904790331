import React, { useEffect, useState } from 'react';
import ListingFeedTemplate from '../../templates/listing-feed';

export default function FeedListingPage({ params }) {
	console.log(params);
	const slug = params.slug || '';
	const tokens = slug.split('-');
	const mls = tokens[tokens.length - 1];
	const url = `https://api.repliers.io/listings/${mls}`;
	const options = {
		method: 'GET',
		headers: {
			accept: 'application/json',
			'REPLIERS-API-KEY': 'FGdHrOEzme6yufWFz7ap7hUhuAaRsl',
		},
	};

	const [listing, setListing] = useState({});
	useEffect(() => {
		fetch(url, options)
			.then((res) => res.json())
			.then((json) => {
				console.log(json);
				setListing(json);
			})
			.catch((err) => console.error('error:' + err));
	}, []);
	return <ListingFeedTemplate listing={listing} />;
}
