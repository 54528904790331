import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import AccentLine from '../components/AccentLine';
import ListingImagesCarousel from '../components/carousels/ListingImagesCarousel';
import ListingMap from '../components/map/ListingMap';
import ShowingForm from '../components/forms/ShowingForm';

import mdToHtml from '../util/mdToHtml';

import locationIcon from '../img/location_icon.svg';
import bedIcon from '../img/bed_listingdetail.svg';
import bathIcon from '../img/bath_listingdetail.svg';
import sqftIcon from '../img/sqft_listingdetail.svg';
import mapBg from '../img/map_bg.jpg';

import Lightbox from 'yet-another-react-lightbox';
import '../styles/lightbox.css';

const StyledArticle = styled.article`
	.masthead-section {
		height: 50vh;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-color: ${(props) => props.theme.colors.secondary};
		filter: grayscale(1);

		@media (max-width: 768px) {
			height: 40vh;
		}

		.container {
			height: 100%;

			.columns {
				height: 100%;

				.column {
					@media (max-width: 768px) {
						height: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}

		img {
			width: 4.5rem;
			height: auto;
			margin-bottom: 2rem;

			p {
				margin-bottom: 1rem;
			}

			@media (max-width: 768px) {
				width: 2.5rem;
				margin-bottom: 1.5rem;
			}
		}

		h1 {
			color: ${(props) => props.theme.colors.lightest};
			letter-spacing: 0.12em;

			@media (max-width: 768px) {
				font-size: 2.4rem;
			}

			span {
				display: block;
				font-family: ${(props) => props.theme.fonts.primary};
				font-weight: ${(props) => props.theme.fonts.weights.bold};
				text-transform: uppercase;
				font-size: 2.5rem;
				color: ${(props) => props.theme.colors.quaternary};
				letter-spacing: 0.02em;
				margin-top: 1em;

				@media (max-width: 768px) {
					font-size: 1.2rem;
				}
			}
		}
	}

	.info-bar {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: center;
		flex-wrap: wrap;

		.left-column {
			width: 60%;
			background-color: ${(props) => props.theme.colors.quaternary};
			padding: 4rem 10rem;

			@media (max-width: 768px) {
				width: 100%;
				padding: 3rem 2rem;
			}

			.numbers {
				@media (min-width: 769px) {
					max-width: 900px;
					margin-left: auto;
				}

				@media (min-width: 769px) and (max-width: 1099px) {
					flex-wrap: wrap;

					> .column {
						width: 100%;
					}
				}

				h3 {
					text-transform: uppercase;
					font-size: 2.3rem;

					span {
						font-size: 1.6em;
						font-weight: ${(props) => props.theme.fonts.weights.bold};
						color: ${(props) => props.theme.colors.primary};
					}

					@media (max-width: 768px) {
						font-size: 1.8rem;
					}
				}

				p {
					font-size: 2.3rem;
					margin-bottom: 1rem;
				}

				ul {
					display: flex;
					align-items: center;
					justify-content: flex-start;

					li {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						font-weight: ${(props) => props.theme.fonts.weights.bold};
						font-size: 2.1rem;
						text-transform: uppercase;
						line-height: 1;

						@media (max-width: 768px) {
							font-size: 2rem;
						}

						+ li {
							margin-left: 1.5rem;

							@media (max-width: 768px) {
								margin-left: 1rem;
							}
						}

						img {
							width: 4rem;
							height: auto;
							margin-right: 1.5rem;

							@media (max-width: 768px) {
								margin-right: 1rem;
								width: 2.5rem;
							}
						}
					}
				}
			}
		}

		.right-column {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			width: 40%;
			background-color: ${(props) => props.theme.colors.primary};

			@media (max-width: 768px) {
				width: 100%;
				align-items: center;
			}

			a {
				display: inline-block;
				font-size: 2.4rem;
				font-weight: ${(props) => props.theme.fonts.weights.bold};
				text-transform: uppercase;
				color: ${(props) => props.theme.colors.lightest};
				line-height: 1;
				transition: 300ms;
				padding-left: 10rem;

				&:hover {
					text-decoration: underline;
				}

				@media (max-width: 768px) {
					font-size: 1.8rem;
					padding: 4rem 0;
				}
			}
		}
	}

	.main-section {
		padding-top: 2rem;
		padding-bottom: 10rem;

		.image-gall {
			.img-col {
				position: relative;
				&:hover {
					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
						filter: brightness(70%);
						-webkit-filter: brightness(70%);
					}
				}
				&::after {
					content: '';
					width: 32px;
					height: 32px;
					background-image: url(/media/GalleryExpand.svg);
					position: absolute;
					bottom: 16px;
					right: 16px;
					cursor: pointer;
					-webkit-transition: all 500ms ease;
					-moz-transition: all 500ms ease;
					-o-transition: all 500ms ease;
					-ms-transition: all 500ms ease;
					transition: all 500ms ease;	
				}
				&.small::after {
					@media (max-width: 768px) {
						display: none;
					}
				}
			}
			img.small,
			img.big {
				filter: brightness(100%);
				-webkit-filter: brightness(100%);
				object-fit: cover;
				width: 100%;
				height: 100%;
				cursor: pointer;
				-webkit-transition: all 500ms ease;
				-moz-transition: all 500ms ease;
				-o-transition: all 500ms ease;
				-ms-transition: all 500ms ease;
				transition: all 500ms ease;
				
			}
			margin-bottom: 4em;
			
		}

		@media (max-width: 768px) {
			padding-top: 3rem;
			.image-gall {
				margin-bottom: 0;
				img.small {
					display: none;
				}
			}
		}

		hr {
			background-color: ${(props) => props.theme.colors.quaternary};
		}

		h3 {
			font-weight: bold;
		}

		.carousel-row {
			@media (max-width: 768px) {
				margin-left: -2rem;
				margin-right: -2rem;
			}
			@media (min-width: 769px) {
				margin-bottom: 5rem;
			}
		}

		.details-row {
			margin-bottom: 8rem;

			.description-column {
				h2 {
					font-family: ${(props) => props.theme.fonts.primary};
					font-weight: bold;
					color: ${(props) => props.theme.colors.primary};
					font-size: 2rem;
					text-transform: none;
				}

				[class*='AccentLine'] {
					margin-top: 2rem;
					margin-bottom: 2rem;
				}
			}
		}

		.features-row {
			h2 {
				font-size: 2.1rem;
				margin-top: 5rem;
				padding-bottom: 2rem;
				margin-bottom: 2rem;
				border-bottom: 1px solid #dedede;
			}

			ul {
				margin-top: 3rem;

				@media (min-width: 500px) {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					flex-wrap: wrap;
				}

				li {
					font-size: 2.1rem;
					padding-left: 2rem;
					margin-right: 3%;
					margin-bottom: 2rem;
					position: relative;

					&::before {
						content: '';
						width: 0.7rem;
						height: 0.7rem;
						background-color: ${(props) => props.theme.colors.primary};
						border-radius: 50%;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
	}

	.map-form-section {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: center;
		flex-wrap: wrap;
		background-color: ${(props) => props.theme.colors.quaternary};

		.map-column {
			width: 60%;
			min-height: 60rem;
			background-image: url(${mapBg});
			background-size: cover;
			background-position: bottom center;
			background-repeat: no-repeat;

			@media (max-width: 768px) {
				width: 100%;
				order: 2;
			}
		}

		.form-column {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 40%;
			padding: 5rem;

			@media (max-width: 768px) {
				width: 100%;
				align-items: center;
				order: 1;
				padding: 5rem 1.5rem;
				min-height: 30rem;

				[class*='ShowingForm'] {
					width: 100%;
				}
			}
		}
	}
`;

const formatMoney = (input) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 0,
	});

	return formatter.format(input);
};

const formatMetersToFeet = (meters) => {
	const feet = meters * 3.28084;
	const wholeFeet = Math.floor(feet);
	const inches = (feet - wholeFeet) / 12;
	return `${wholeFeet}'${Math.round(inches)}"`;
};

const getRooms = (listing) => {
	if (!listing?.rooms) {
		return null;
	}

	const groups = Object.keys(listing.rooms)
		.map((key) => listing.rooms[key])
		.reduce((r, a) => {
			r[a.level] = r[a.level] || [];
			r[a.level].push(a);
			return r;
		}, Object.create(null));

	return Object.keys(groups).map((key) => (
		<>
			<h3>{key.toUpperCase()}</h3>
			{groups[key].map((room) => (
				<>
					<div>
						{room.description}
						{': '}
						<strong>
							{room.length && room.width
								? `${formatMetersToFeet(room.length)} x ${formatMetersToFeet(
									room.width
								)}`
								: ''}
						</strong>
						{room.description == "Bathroom" && room?.features &&
							<strong> - {room.features}</strong>
						}
					</div>
				</>
			))}
			<br />
		</>
	));
};

const getImg = (listing, index) => {
	if (!listing?.images) {
		return '';
	} else if (listing.images[index]) {
		return `https://cdn.repliers.io/${listing.images[index]}`;
	} else {
		return '';
	}
};

export const ListingTemplate = ({ listing }) => {
	const [open, setOpen] = useState(false);
	const [index, setIndex] = useState(0);
	return (
		<StyledArticle>
			<section
				className="section masthead-section"
				style={{
					backgroundImage: `  linear-gradient(
					rgba(39, 39, 39, 0.65),
					rgba(39, 39, 39, 0.65)
				),url(https://cdn.repliers.io/${listing?.images && listing.images[0] ? listing.images[0] : ''
						})`,
				}}
			>
				<div className="container">
					<div className="columns is-centered is-vcentered">
						<div className="column has-text-centered">
							<img src={locationIcon} alt="" />
							<h1>
								{getTitle(listing)}
								<br />
								<span>
									{listing?.address?.city ? listing.address.city : ''},{' '}
									{listing?.address?.state ? listing.address.state : ''}
								</span>
							</h1>
						</div>
					</div>
				</div>
			</section>
			<section className="info-bar">
				<div className="left-column">
					<div className="columns numbers">
						<div className="column is-5">
							<h3>
								For Sale <br />
								<span>{formatMoney(listing.listPrice)}</span>
							</h3>
						</div>
						<div className="column is-7">
							<p>
								MLS&reg; Number: {listing?.mlsNumber ? listing.mlsNumber : ''}
							</p>
							<ul>
								<li>
									<img src={bedIcon} alt="" />
									{listing?.details?.numBedrooms
										? listing.details.numBedrooms
										: 0}
									+
									{listing?.details?.numBedroomsPlus
										? listing.details.numBedroomsPlus
										: 0}
								</li>
								<li>
									<img src={bathIcon} alt="" />
									{listing?.details?.numBathrooms
										? listing.details.numBathrooms
										: 0}
									+
									{listing?.details?.numBathroomsPlus
										? listing.details.numBathroomsPlus
										: 0}
								</li>
								<li>
									<img src={sqftIcon} alt="" />
									{listing?.details?.sqft ? listing.details.sqft : ''} sq. ft.
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="right-column">
					<a href="#schedule">Schedule a Showing</a>
				</div>
			</section>
			<section className="section main-section">
				<div className="image-gall">
					<div className="columns">
						<div className="column img-col" onClick={() => {
							setIndex(0);
							setOpen(true);
						}}>
							<img
								className="big"
								src={getImg(listing, 0)}
								onClick={() => {
									setIndex(0);
									setOpen(true);
								}}
							/>
						</div>
						<div className="column">
							<div className="columns">
								<div className="column img-col small" onClick={() => {
									setIndex(0);
									setOpen(true);
								}}>
									<img
										className="small"
										src={getImg(listing, 1)}

									/>
								</div>
								<div className="column img-col small" onClick={() => {
									setIndex(0);
									setOpen(true);
								}}>
									<img
										className="small"
										src={getImg(listing, 3)}

									/>
								</div>
							</div>
							<div className="columns">
								<div className="column img-col small" onClick={() => {
									setIndex(0);
									setOpen(true);
								}}>
									<img
										className="small"
										src={getImg(listing, 2)}

									/>
								</div>
								<div className="column img-col small" onClick={() => {
									setIndex(0);
									setOpen(true);
								}}>
									<img
										className="small"
										src={getImg(listing, 4)}

									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Lightbox
					open={open}
					index={index}
					close={() => {
						setOpen(false);
					}}
					slides={
						listing.images
							? listing.images.map((img) => ({
								src: `https://cdn.repliers.io/${img}`,
							}))
							: []
					}
				/>
				<div className="container">
					{/* 
				{listing?.images && listing.images.length > 0 ? (
					<div className="columns carousel-row is-centered">
						<div className="column is-10">
							<ListingImagesCarousel
								images={listing.images.map((image) => ({
									image: `https://cdn.repliers.io/${image}`,
								}))}
							/>
						</div>
					</div>
				) : null}
				*/}
					<div className="columns details-row">
						<div className="column description-column">
							<h2>LISTING DESCRIPTION</h2>
							<AccentLine />
							{listing?.details?.description && (
								<div
									dangerouslySetInnerHTML={{
										__html: mdToHtml(listing.details.description),
									}}
								/>
							)}
						</div>
					</div>
					<div className="columns features-row">
						<div className="column is-4">
							<h2>PROPERTY SUMMARY</h2>
							{listing?.details?.propertyType ? (
								<div>
									PROPERTY TYPE: <strong>{listing.details.propertyType}</strong>
								</div>
							) : null}
							{listing?.details?.sqft ? (
								<div>
									SQUARE FOOTAGE: <strong>{listing.details.sqft} sq. ft.</strong>
								</div>
							) : null}
							{listing?.taxes?.annualAmount ? (
								<div>
									ANNUAL PROPERTY TAXES:{' '}
									<strong>${listing.taxes.annualAmount}</strong>
								</div>
							) : null}
							{listing?.details?.numParkingSpaces ? (
								<div>
									PARKING SPOTS:{' '}
									<strong>{listing.details.numParkingSpaces}</strong>
								</div>
							) : null}
						</div>
						<div className="column is-8">
							<div className="columns">
								<div className="column is-12">
									<h2>BUILDING</h2>
								</div>
							</div>
							<div className="columns">
								<div className="column">
									<h3>BEDROOMS</h3>
									{listing?.details?.numBedrooms ? (
										<div>
											ABOVE GRADE:{' '}
											<strong>{listing.details.numBedrooms}</strong>
										</div>
									) : (
										<div>
											ABOVE GRADE: <strong>0</strong>
										</div>
									)}
									{listing?.details?.numBathroomsPlus ? (
										<div>
											BELOW GRADE:{' '}
											<strong>{listing.details.numBedroomsPlus}</strong>
										</div>
									) : (
										<div>
											BELOW GRADE: <strong>0</strong>
										</div>
									)}
									<br />
									<h3>BATHROOMS</h3>
									{listing?.details?.numBathrooms ? (
										<div>
											TOTAL: <strong>{listing.details.numBathrooms}</strong>
										</div>
									) : (
										<div>
											TOTAL: <strong>0</strong>
										</div>
									)}
									{listing?.details?.numBathroomsPlus ? (
										<div>
											PARTIAL:{' '}
											<strong>{listing.details.numBathroomsPlus}</strong>
										</div>
									) : (
										<div>
											PARTIAL: <strong>0</strong>
										</div>
									)}
									<br />
									{listing?.details?.basement1 &&
										<h3>INTERIOR FEATURES</h3>}
									{listing?.details?.basement1 ? (
										<div>
											BASEMENT TYPE:{' '}
											<strong>{listing.details.basement1}</strong>
										</div>
									) : (
										''
									)}
									<br />
									{(listing?.details?.style || listing?.details?.foundationType) &&
										<h3>BUILDING FEATURES</h3>}
									{listing?.details?.style ? (
										<div>
											STYLE: <strong>{listing.details.style}</strong>
										</div>
									) : null}
									{listing?.details?.foundationType ? (
										<div>
											FOUNDATION TYPE:{' '}
											<strong>{listing.details.foundationType}</strong>
										</div>
									) : null}
								</div>
								<div className="column">
									{(listing?.details?.airConditioning || listing?.details?.heating) &&
										<h3>HEATING & COOLING</h3>}
									{listing?.details?.airConditioning ? (
										<div>
											COOLING:{' '}
											<strong>{listing.details.airConditioning}</strong>
										</div>
									) : null}
									{listing?.details?.heating ? (
										<div>
											HEATING TYPE: <strong>{listing.details.heating}</strong>
										</div>
									) : null}
									<br />
									{(listing?.details?.landSewer || listing?.details?.waterSource) &&
										<h3>UTILITIES</h3>}
									{listing?.details?.landSewer ? (
										<div>
											UTILITY SEWER:{' '}
											<strong>{listing.details.landSewer}</strong>
										</div>
									) : null}
									{listing?.details?.waterSource ? (
										<div>
											WATER: <strong>{listing.details.waterSource}</strong>
										</div>
									) : null}
									<br />
									{(listing?.details?.exteriorConstruction1 || listing?.details?.numParkingSpaces) &&
										<h3>EXTERIOR FEATURES</h3>}
									{listing?.details?.exteriorConstruction1 ? (
										<div>
											EXTERIOR FINISH:{' '}
											<strong>{listing.details.exteriorConstruction1}</strong>
										</div>
									) : null}
									<br />
									<h3>PARKING</h3>
									{listing?.details?.numParkingSpaces ? (
										<div>
											TOTAL PARKING SPACES:{' '}
											<strong>{listing.details.numParkingSpaces}</strong>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
					<div className="columns features-row">
						<div className="column is-4">
							<h2>MEASUREMENTS</h2>
							{listing?.details?.sqft ? (
								<div>
									SQUARE FOOTAGE: <strong>{listing.details.sqft} sq. ft.</strong>
								</div>
							) : null}
							<h2>LAND</h2>
							{listing?.lot?.width ? (
								<div>
									FRONTAGE: <strong>{listing.lot.width} ft.</strong>
								</div>
							) : null}
							{listing?.lot?.depth ? (
								<div>
									DEPTH: <strong>{listing.lot.depth} ft</strong>
								</div>
							) : null}
							<br />
							{listing?.details?.zoning ? (
								<div>
									<h2>OTHER PROPERTY INFORMATION</h2>
									ZONING DESCRIPTION: <strong>{listing.details.zoning}</strong>
								</div>
							) : null}
							<br />
							{listing?.details?.waterfront ? (
								<>
									<h2>WATERFRONT FEATURES</h2>
									<div>
										WATERFRONT: <strong>{listing.details.waterfront}</strong>
									</div>
								</>
							) : null}
						</div>
						<div className="column is-8">
							<h2>ROOMS</h2>
							{getRooms(listing)}
						</div>
					</div>
					<div className="columns features-row">
						<div className="column">
							<h2>BROKERAGE</h2>
							{listing?.office?.brokerageName ? (
								<div>
									<strong style={{ fontSize: '24px' }}>
										{listing.office.brokerageName}
									</strong>
								</div>
							) : null}
							<br />
							<br />
							<br />
							<br />
							<p>
								Data provided by: Barrie & District Association of REALTORS®
								Inc. 676 Veterans Drive, Barrie, Ontario L9J 0H6
							</p>
						</div>
					</div>
				</div>
			</section>
			<section className="map-form-section">
				<div className="map-column">
					{listing?.map && listing?.map?.longitude && listing?.map?.latitude ? (
						<ListingMap
							title={getTitle(listing)}
							lat={listing.map.latitude}
							lng={listing.map.longitude}
							googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDbA3ifgEFvRv_TdcbtxBJ7-IlyaFwmr9Q"
							loadingElement={<div style={{ height: `100%` }} />}
							containerElement={<div style={{ height: `650px` }} />}
							mapElement={<div style={{ height: `100%` }} />}
						/>
					) : null}
				</div>
				<div className="form-column" id="schedule">
					<ShowingForm
						listing={{
							title: getTitle(listing),
							city: listing?.address?.city ? listing.address.city : '',
						}}
					/>
				</div>
			</section>
		</StyledArticle>
	);
};

const getSlug = (listing) => {
	return `${listing?.address?.streetNumber
		? listing.address.streetNumber.toLowerCase()
		: ''
		}-${listing?.address?.streetName ? listing.address.streetName.toLowerCase() : ''
		}-${listing?.address?.streetSuffix
			? listing.address.streetSuffix.toLowerCase()
			: ''
		}-${listing?.address?.city ? listing.address.city.toLowerCase() : ''}-${listing.mlsNumber
		}`.replace(' ', '-');
};

const getTitle = (listing) => {
	return `${listing?.address?.streetNumber ? listing.address.streetNumber : ''
		} ${listing?.address?.streetName ? listing?.address?.streetName : ''} ${listing?.address?.streetSuffix ? listing.address.streetSuffix : ''
		}`;
};

const Listing = ({ listing }) => {
	return (
		<Layout>
			<SEO
				title={getTitle(listing)}
				image={listing?.images ? listing.images[0] : ''}
				description={
					listing?.details?.description ? listing.details.description : ''
				}
				slug={getSlug(listing)}
			/>
			<ListingTemplate listing={listing} />
		</Layout>
	);
};

export default Listing;

/*
export const listingQuery = graphql`
	query Listing($id: String!) {
		markdownRemark(id: { eq: $id }) {
			fields {
				slug
			}
			frontmatter {
				title
				city
				province
				price
				mls_number
				baths
				beds
				footage
				description {
					heading
					body
				}
				featured_image {
					image
				}
				listing_images {
					alt
					image
				}
				listing_images_multi
				features
				map {
					lat
					lng
				}
			}
		}
	}
`;
*/
